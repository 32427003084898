<template>
  <div>
    <b-row v-if="balance.length > 0">
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="balance"
            :fields="field"
            @row-dblclicked="test"
            head-row-variant="secondary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(history)="data">
              <span @dblclick="getHistory(data)" style="color: blue">
                (***)
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
export default {
  data() {
    return {
      ID: this.$route.query.id,
      balance: [],
      field: [
        // {key: 'checkbox', label: '', thStyle: {width: '5px'}},
        { key: "client_id", label: "ID", thStyle: { width: "5px" } },
        {
          key: "client_phone",
          label: "Номер телефона",
          thStyle: { width: "40px" },
        },
        { key: "type", label: "Тип", thStyle: { width: "30px" } },
        {
          key: "type_name",
          label: "Наименование типа",
          thStyle: { width: "50px" },
        },
        { key: "balance", label: "Баланс", thStyle: { width: "30px" } },
        { key: "created_at", label: "Создан", thStyle: { width: "40px" } },
        { key: "history", label: "История", thStyle: { width: "30px" } },
      ],
    };
  },
  methods: {
    test() {
      let division_id = JSON.parse(localStorage.getItem("userData"));
    },
    getHistory(data) {
      this.$store.commit("draggableTab/ADD_TAB", [
        "История баланса " + this.ID,
        "/bonus-history",
        this.ID,
      ]);
      this.$router.push({ name: "bonus-history", query: { id: this.ID } });
    },
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    resizeable();
    this.$http.get(`clients/${this.$route.query.id}/balances`).then((res) => {
      this.balance = res.data;
      this.$store.commit("pageData/setdataCount", this.balance.length);
    });
  },
  watch: {
    "$route.query.filter": function (newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$http
          .get(`clients/${this.$route.query.id}/balances`)
          .then((res) => {
            this.balance = res.data;
            this.$store.commit("pageData/setdataCount", this.balance.length);
          });
      }
    },
  },
};
</script>

<style></style>
